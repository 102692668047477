import { render, staticRenderFns } from "./district.detail.vue?vue&type=template&id=6b0ca294&"
import script from "./district.detail.vue?vue&type=script&lang=js&"
export * from "./district.detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\client\\Admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b0ca294')) {
      api.createRecord('6b0ca294', component.options)
    } else {
      api.reload('6b0ca294', component.options)
    }
    module.hot.accept("./district.detail.vue?vue&type=template&id=6b0ca294&", function () {
      api.rerender('6b0ca294', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/districts/district.detail.vue"
export default component.exports