import http from '../../services/helpers/http';
import enums from '../../services/helpers/enums';
import i18n from '../../services/lang';

const validators = {
	async numberLength() {
		if (!this.model.DistrictUrlName || this.model.DistrictUrlName.isNullOrWhiteSpace()) {
			return [i18n.t('amistake!')];
		}

		return [];
	},

	async DistrictUrlName() {
		if (!this.model.DistrictUrlName || this.model.DistrictUrlName.isNullOrWhiteSpace()) {
			return [i18n.t('amistake!')];
		}

		if (
			!this.model.DistrictUrlName ||
			this.model.DistrictUrlName.isNullOrWhiteSpace() ||
			this.model.DistrictUrlName.indexOf(' ') >= 0
		) {
			return [i18n.t('amistake!')];
		}

		if (!this.model.DistrictUrlName || this.model.DistrictUrlName.withoutDiacritics() != this.model.DistrictUrlName) {
			return [i18n.t('amistake!')];
		}

		const resp = await http.get('District/ExistsUrl', {
			districtId: this.model.DistrictId,
			url: this.model.DistrictUrlName,
		});

		if (resp.data === true) {
			return [i18n.t('urlnamemustbeor')];
		}

		return [];
	},
	required() {
		switch (this.schema.model) {
			case 'DistrictNameSk':
				if (
					this.model.CountryId === enums.country.SK &&
					(!this.model.DistrictNameSk || this.model.DistrictNameSk.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'DistrictNameLokalSk':
				if (
					this.model.CountryId === enums.country.SK &&
					(!this.model.DistrictNameSk || this.model.DistrictNameLokalSk.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'DistrictNameCz':
				if (
					this.model.CountryId === enums.country.CZ &&
					(!this.model.DistrictNameCz || this.model.DistrictNameCz.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'DistrictNameLokalCz':
				if (
					this.model.CountryId === enums.country.CZ &&
					(!this.model.DistrictNameCz || !this.model.DistrictNameLokalCz)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'DistrictNamePl':
				if (
					this.model.CountryId === enums.country.PL &&
					(!this.model.DistrictNamePl || this.model.DistrictNamePl.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'DistrictNameLokalPl':
				if (
					this.model.CountryId === enums.country.PL &&
					(!this.model.DistrictNamePl || !this.model.DistrictNameLokalPl)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
		}
	},
};

export default validators;
