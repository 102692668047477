import vueFormGenerator from 'vue-form-generator';
import validators from './district.validators';
import commonValidator from '@/services/helpers/validator.common';

export const model = {
	DistrictId: 0,
	DistrictNameSk: null,
	DistrictNameCz: null,
	DistrictNamePl: null,
	DistrictNameDe: null,
	DistrictNameEn: null,
	DistrictNameHu: null,
	DistrictNameRu: null,
	DistrictNameLokalSk: null,
	DistrictNameLokalCz: null,
	DistrictNameLokalPl: null,
	DistrictNameLokalDe: null,
	DistrictNameLokalEn: null,
	DistrictNameLokalHu: null,
	DistrictNameLokalRu: null,
	DomainUrl: null,
	DistrictUrlName: null,
	DistrictGoogleMapsGpsLat: null,
	DistrictGoogleMapsGpsLng: null,
	DistrictGoogleMapsZoom: null,
	CountryId: null,
	TerritoryId: null,
	DistrictAbbr: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameSk',
				validator: validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameLokalSk',
				validator: validators.required,
				i18n: {
					label: 'localsk2557',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameCz',
				validator: validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameLokalCz',
				validator: validators.required,
				i18n: {
					label: 'localcz2559',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNamePl',
				validator: validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameLokalPl',
				validator: validators.required,
				i18n: {
					label: 'localcz2560',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameEn',
				validator: validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameLokalEn',
				validator: validators.required,
				i18n: {
					label: 'localen',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameDe',
				validator: validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameLokalDe',
				validator: validators.required,
				i18n: {
					label: 'localde2563',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameHu',
				validator: validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictNameLokalHu',
				validator: validators.required,
				i18n: {
					label: 'localhu2567',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DistrictUrlName',
				required: true,
				validator: validators.DistrictUrlName,
				i18n: {
					label: 'url',
				},
			},
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'select',
				model: 'TerritoryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'value',
					name: 'text',
				},
				i18n: {
					label: 'county3266',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'DistrictGoogleMapsGpsLat',
				required: true,
				// validator: vueFormGenerator.validators.double,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'latitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'DistrictGoogleMapsGpsLng',
				required: true,
				//validator: vueFormGenerator.validators.double,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'longitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'DistrictGoogleMapsZoom',
				required: true,
				validator: vueFormGenerator.validators.integer,
				i18n: {
					label: 'zoomonmap',
				},
			},
		],
	},
];
